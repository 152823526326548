@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';
@import "~@de-electron/design-tokens/dist/web/__all";
@import "./app/styles/fonts";
@import "~@de-electron/component-library/src/global/scss/base/_loader";
@import "~@de-electron/component-library/src/global/scss/base/_reset";
@import "~@de-electron/component-library/src/global/scss/base/_typography";
@import "~@angular/cdk/overlay-prebuilt.css";
@import '@angular/material/prebuilt-themes/indigo-pink.css';

.main-container {
  @include spacing-offset(padding, l, zero);
  display: block;
  min-height: calc(100vh - (53px + 67px)); /* will cover the 100% of viewport */
  overflow: hidden;
  position: relative;
  @include breakpoint(m) {
    min-height: calc(100vh - (95px + 67px)); /* will cover the 100% of viewport */
  }
  @include breakpoint(l) {
    @include spacing-offset(padding, 2xl, zero);
    min-height: calc(100vh - (91px + 95px)); /* will cover the 100% of viewport */
  }
}

h2 {
  font-size: xx-large;
}

.mat-icon-button.mat-button-disabled {
  color: $color-gray-light
}

.error-group {
  text-align: center;
}

button.danger-button {
  text-align: center;
  border-radius: 2px;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: .16px;
  -webkit-transition: .1s ease-in-out;
  transition: .1s ease-in-out;
  padding: 11px;
  background: #e00;
  border: 1px solid #e00;
  color: #fff;
  cursor: pointer;
  pointer-events: auto;
  display: inline-block;
  margin: 8px;
  height: 44px;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
  width: 180px;
}
